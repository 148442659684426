import { Toolbar } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import { APP_CAREPROVIDER, APP_PROVIDER_SEARCH } from "@recare/core/consts";
import Config, { ENV_DEMO, ENV_PREPROD } from "@recare/core/model/config";
import { useEnvContext } from "context/EnvContext";
import { WHITE } from "ds/materials/colors";
import {
  APP_BAR_HEIGHT,
  APP_BAR_PROVIDERSEARCH_HEIGHT,
  Z_INDEX_APP_BAR,
  dp,
  margin,
  padding,
  responsiveCSS,
  sizing,
} from "ds/materials/metrics";
import { SHADOW_DP3 } from "ds/materials/shadows";
import { Body } from "ds/materials/typography";
import { usePrint } from "dsl/atoms/Contexts";
import { useMedia } from "dsl/atoms/ResponsiveMedia";
import React, { CSSProperties, useMemo } from "react";
import { useMatch } from "react-router-dom";
import styled from "styled-components";
import LinkV2 from "../LinkV2";

export const LeftPart = styled.div`
  display: flex;
  flex-shrink: 1;
  justify-content: flex-start;
  align-items: center;
`;

export const CenterTools = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  margin: ${margin(0, 2)};
`;

export const RightPart = styled.div<{ aligned?: boolean }>`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: ${(props) => (props.aligned ? "center" : "stretch")};
`;

export const Centered = styled.div<{
  flex?: boolean;
  margin?: string;
  right?: boolean;
}>`
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: ${({ right }) => (right ? "flex-end" : "center")};
  margin: ${(props) => props.margin || margin(0)};
  flex: ${({ flex }) => flex && "1 1 auto"};
`;

export const LogoWrapper = styled(LinkV2)<{
  flex?: boolean;
  justify?: CSSProperties["justifyContent"];
  margin?: string;
}>`
  align-items: center;
  box-sizing: border-box;
  color: inherit;
  display: flex;
  flex: ${({ flex }) => flex && "1 1 auto"};
  justify-content: ${(props) => (props.justify ? props.justify : undefined)};
  margin: ${(props) => props.margin || margin(0)};
  overflow: hidden;
  text-decoration: none;
`;

export const AppBarDivider = styled.div`
  background-color: ${WHITE};
  width: ${dp(1)};
  margin: ${margin(1)};
  height: ${sizing(3)};
`;

const Content = styled.div<{
  isProviderSearch?: boolean;
}>`
  display: flex;
  flex: 1;
  align-items: stretch;
  box-sizing: border-box;
  padding: ${({ isProviderSearch }) =>
    isProviderSearch ? padding(0, 2, 0, 3) : padding(0, 2)};
  ${({ isProviderSearch }) =>
    responsiveCSS({
      tablet: [`padding: ${padding(0, 2, 0, isProviderSearch ? 3 : 1)}`],
      mobile: [`padding: ${padding(0, isProviderSearch ? 2 : 0.5)}`],
    })}
  @media print {
    display: none;
  }
`;

export const DemoChip = ({ story }: { story?: boolean }) => {
  const demoEnvironments = [ENV_PREPROD, ENV_DEMO];
  const isDemo = useMemo(
    () => story || demoEnvironments.includes(Config.environment),
    [story, Config.environment],
  );
  const { isTablet } = useMedia();

  if (!isDemo || isTablet) return null;

  return (
    <>
      <AppBarDivider />
      <Body color={WHITE} margin={margin(0, 1)}>
        DEMO
      </Body>
    </>
  );
};

function AppBarGeneric({
  backgroundColor,
  boxShadow,
  children,
  height,
  position,
  zIndex = Z_INDEX_APP_BAR,
  isProviderSearch,
}: {
  backgroundColor?: CSSProperties["backgroundColor"];
  boxShadow?: CSSProperties["boxShadow"];
  children?: React.ReactNode;
  height: number;
  isProviderSearch?: boolean;
  position?: CSSProperties["position"];
  zIndex?: CSSProperties["zIndex"];
}) {
  const { isTablet } = useMedia();
  const { app } = useEnvContext();
  const print = usePrint();

  if (print) return null;

  return (
    <Toolbar
      component="header"
      disableGutters
      sx={{
        height: dp(height),
        "&.MuiToolbar-root": { minHeight: dp(height) },
        width: "100%",
        "@media print": { display: "none" },
      }}
    >
      <Paper
        data-testid="app-top-bar"
        style={{
          backgroundColor,
          display: "flex",
          height,
          position: position || "fixed",
          top: 0,
          width: "100%",
          zIndex,
          boxShadow,
        }}
        square
        elevation={app === APP_PROVIDER_SEARCH || isTablet ? 0 : 4}
      >
        <Content isProviderSearch={isProviderSearch}>{children}</Content>
      </Paper>
    </Toolbar>
  );
}

const useGetAppBarBackgroundColor = () => {
  const theme = useTheme();
  const { app } = useEnvContext();

  if (app == APP_CAREPROVIDER) {
    return theme.palette.primary.dark;
  }

  return theme.palette.primary.main;
};

export function AppBar({
  children,
  position,
}: {
  children?: React.ReactNode;
  position?: CSSProperties["position"];
}) {
  const backgroundColor = useGetAppBarBackgroundColor();

  return (
    <AppBarGeneric
      backgroundColor={backgroundColor}
      height={APP_BAR_HEIGHT}
      position={position}
    >
      {children}
    </AppBarGeneric>
  );
}

export function AppBarProviderSearch({
  children,
  filtersDrawerOpen,
  position,
}: {
  children?: React.ReactNode;
  filtersDrawerOpen: boolean;
  position?: CSSProperties["position"];
}) {
  const theme = useTheme();
  const isListingPage = useMatch("providerSearch/:provider_search/listing/*");

  return (
    <AppBarGeneric
      height={APP_BAR_PROVIDERSEARCH_HEIGHT}
      backgroundColor={theme.palette.common.white}
      boxShadow={isListingPage ? undefined : SHADOW_DP3}
      position={position}
      zIndex={filtersDrawerOpen ? 0 : undefined}
      isProviderSearch
    >
      {children}
    </AppBarGeneric>
  );
}
