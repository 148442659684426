import { useProviderSearchContext } from "context/ProviderSearchContext";
import { getAcpNavigationHandlers } from "entries/acp/Router/acpNavigation";
import {
  getB2CNavigationHandlers,
  getB2CNavigationUrls,
} from "entries/b2c/Router/b2cNavigationHandlers";
import {
  getProviderSearchNavigationHandlers,
  getProviderSearchNavigationUrls,
} from "entries/providersearch/Router/providerSearchNavigationHandlers";
import { getCareproviderNavigationHandlers } from "entries/receiver/Router/navigation";
import {
  getCareseekerNavigationHandlers,
  useEnhancedNavigate,
} from "entries/sender/Router/appNavigationHandlers";
import { useLocation, useMatch, useNavigate } from "react-router-dom";

export type CareseekerNavigationHandlers = ReturnType<
  typeof useCareseekerNavigationHandlers
>;

export type CareproviderNavigationHandlers = ReturnType<
  typeof useCareproviderNavigationHandlers
>;

export type AcpNavigationHandlers = ReturnType<typeof useAcpNavigationHandlers>;
export type B2CNavigationHandlers = ReturnType<typeof getB2CNavigationHandlers>;
export type ProviderSearchNavigationHandlers = ReturnType<
  typeof getProviderSearchNavigationHandlers
>;

export function useCareseekerNavigationHandlers() {
  const navigate = useEnhancedNavigate();
  const location = useLocation();
  return getCareseekerNavigationHandlers({
    location,
    navigate,
  });
}

export function useCareproviderNavigationHandlers() {
  const navigate = useNavigate();
  const location = useLocation();
  const match = useMatch("/careprovider/dashboard/:careproviderId/*");

  return getCareproviderNavigationHandlers({
    navigate,
    location,
    careproviderId: match?.params.careproviderId,
  });
}

export function useAcpNavigationHandlers() {
  const navigate = useNavigate();
  const location = useLocation();
  return getAcpNavigationHandlers({ navigate, search: location.search });
}

export function useB2CNavigationUrls() {
  const location = useLocation();
  return getB2CNavigationUrls({
    location,
  });
}
export function useB2CNavigationHandlers() {
  const navigate = useNavigate();
  const location = useLocation();
  return getB2CNavigationHandlers({ navigate, location });
}

export function useProvidersearchNavigationHandlers() {
  const navigate = useNavigate();
  const location = useLocation();
  const context = useProviderSearchContext();

  return getProviderSearchNavigationHandlers({
    navigate,
    location,
    providerSearchContext: context,
  });
}

export function useProvidersearchNavigationUrls() {
  const location = useLocation();
  const context = useProviderSearchContext();

  return getProviderSearchNavigationUrls({
    location,
    providerSearchContext: context,
  });
}
