import { ApolloClient } from "@apollo/client";
import {
  ACCOUNT_TYPE_STAFF,
  SEALD_CONTEXT_TYPE_SESSION,
} from "@recare/core/consts";
import { activateSealdSessionEncryption } from "@recare/core/model/utils/featureFlags";
import {
  Account,
  ApolloCacheData,
  AppType,
  AuctionRequest,
  EnvContext,
  Filev2,
  Patient,
  SealdAccessPayload,
} from "@recare/core/types";
import { EncryptionSession } from "@seald-io/sdk/lib/main.js";
import {
  useCreateEncryptionContext,
  useDeleteSealdAccess,
} from "apollo/hooks/mutations";
import { computeSealdDisplayId, getSealdSDKInstance } from ".";
import {
  getIdsFromSealdDisplayIds,
  updateSealdContextInApolloCache,
} from "./utils";

type EncryptedSessionType = "auction_request" | "file" | "patient";

export type CreateSessionInContextProps = {
  checkSealdGroupAccess: (payload: {
    careprovider_id?: number;
    careseeker_id?: number;
  }) => Promise<{ exists: boolean }>;
  createEncryptionContext: ReturnType<typeof useCreateEncryptionContext>[0];
  createSealdAccess: (
    sealdAccess: Partial<SealdAccessPayload>,
  ) => Promise<null>;
  entity: AuctionRequest | Patient | Filev2 | null | undefined;
  entityType: EncryptedSessionType;
  entityUsersDisplayIds: Array<string>;
  loggedGroupDisplayId: string;
};

export async function createSealdSession({
  apolloClient,
  app,
  checkSealdGroupAccess,
  createEncryptionContext,
  createSealdAccess,
  entity,
  entityType,
  entityUsersDisplayIds,
  envContext,
  loggedAccount,
  loggedGroupDisplayId,
}: CreateSessionInContextProps & {
  apolloClient: ApolloClient<ApolloCacheData>;
  app: AppType;
  envContext: EnvContext;
  loggedAccount: Account | undefined;
}) {
  if (!activateSealdSessionEncryption(app)) return;

  if (!entity || entity?.seald_encryption_context) return;

  const entityId = entity.id;

  if (!loggedAccount) return;

  if (loggedAccount.account_type === ACCOUNT_TYPE_STAFF) {
    console.log("Encrypted Session creation skipped for staff account");
    return;
  }

  const sealdSDKInstance = await getSealdSDKInstance();
  if (!sealdSDKInstance) return;

  const sealdSessionId = computeSealdDisplayId({
    id: entityId,
    type: entityType,
    envContext,
  });

  const [idProp, id] = getIdsFromSealdDisplayIds([loggedGroupDisplayId])[0];
  const hasGroupAccess = await checkSealdGroupAccess({ [idProp]: id })
    .then(({ exists }) => exists)
    .catch(() => false);

  const encryptForSelf = hasGroupAccess
    ? false // account already belongs to one of the groups being added
    : true; // account doesn't belong to the group yet, so needs to temporarily add itself to the session
  const session = await sealdSDKInstance.createEncryptionSession(
    {
      userIds: entityUsersDisplayIds,
    },
    {
      encryptForSelf,
      metadata: sealdSessionId,
      allowUnregisteredUsers: true,
    },
  );

  if (!session) return;

  const { data } = await createEncryptionContext({
    seald_id: session.sessionId,
    seald_type: SEALD_CONTEXT_TYPE_SESSION,
    [`${entityType}_id`]: entityId,
  });

  if (!data?.encryptionContext) {
    throw new Error("No encryptionContext returned");
  }
  const { encryptionContext } = data;

  // create accesses for careseekers/careproviders
  for (const [idProp, id] of getIdsFromSealdDisplayIds(entityUsersDisplayIds)) {
    await createSealdAccess({
      [idProp]: id,
      seald_id: session.sessionId,
      encrypt_for_self: encryptForSelf,
    });
  }

  // write to cache AFTER the accesses are created, so the seald context has_access is synced
  // this retriggers the entity query if it is network-only
  updateSealdContextInApolloCache({
    apolloClient,
    encryptionContext,
    entityType,
    id: entityId,
  });

  return encryptionContext;
}

export async function encryptForSession({
  context,
  message,
  session,
  sessionId,
}: {
  context?: string;
  message: string | undefined;
  session?: EncryptionSession | undefined;
  sessionId?: string | undefined;
}) {
  try {
    if ((sessionId || session) && message) {
      let sessionInstance = session;
      if (!sessionInstance) {
        const sealdSDKInstance = await getSealdSDKInstance();
        sessionInstance = await sealdSDKInstance?.retrieveEncryptionSession({
          sessionId,
        });
      }
      const encryptedMessage = await sessionInstance?.encryptMessage(message);
      return encryptedMessage;
    }
  } catch (encryptError) {
    console.error(`Seald: Failed to encrypt ${context}:`, encryptError);
  }
}

export async function decryptFromSession({
  context,
  encryptedMessage,
  session,
}: {
  context?: string;
  encryptedMessage: string | null | undefined;
  session?: EncryptionSession | undefined;
}) {
  const session_id_1 = session?.sessionId ?? "none";
  let session_id_2 = "none";
  try {
    if (encryptedMessage) {
      if (encryptedMessage == "redacted") return undefined;
      let sessionInstance = session;
      if (!sessionInstance) {
        const sealdSDKInstance = await getSealdSDKInstance();
        sessionInstance = await sealdSDKInstance?.retrieveEncryptionSession({
          encryptedMessage,
        });
        session_id_2 = sessionInstance?.sessionId ?? "none*";
      }
      const decryptedMessage = await sessionInstance?.decryptMessage(
        encryptedMessage,
      );
      return decryptedMessage;
    }
  } catch (decryptError) {
    console.error(`Seald: Failed to decrypt ${context}:`, decryptError, {
      session_id_1,
      session_id_2,
      encryptedMessage,
    });
  }
}

export async function addUsersToSession({
  createSealdAccess,
  entityUsersDisplayIds,
  sessionId,
}: {
  createSealdAccess: (
    sealdAccess: Partial<SealdAccessPayload>,
  ) => Promise<null>;
  entityUsersDisplayIds: Array<string>;
  sessionId: string | undefined;
}) {
  try {
    const sealdSDKInstance = await getSealdSDKInstance();
    const session = await sealdSDKInstance?.retrieveEncryptionSession({
      sessionId,
    });

    if (!session) return;

    await session.addRecipients(
      { userIds: entityUsersDisplayIds },
      { allowUnregisteredUsers: true },
    );

    for (const [idProp, id] of getIdsFromSealdDisplayIds(
      entityUsersDisplayIds,
    )) {
      await createSealdAccess({
        [idProp]: id,
        seald_id: session.sessionId,
      });
    }
  } catch (inviteError) {
    console.groupCollapsed("Seald: Failed to invite users");
    console.error(
      `Seald: Failed to invite users ${JSON.stringify(
        entityUsersDisplayIds,
      )} to session ${sessionId}`,
      inviteError,
    );
    console.groupEnd();
  }
}

export async function removeUsersFromSession({
  deleteSealdAccess,
  entityUsersDisplayIds,
  sessionId,
}: {
  deleteSealdAccess: ReturnType<typeof useDeleteSealdAccess>[0];
  entityUsersDisplayIds: Array<string>;
  sessionId: string | undefined;
}) {
  try {
    const sealdSDKInstance = await getSealdSDKInstance();
    const session = await sealdSDKInstance?.retrieveEncryptionSession({
      sessionId,
    });
    await session?.revokeRecipients(
      { userIds: entityUsersDisplayIds },
      { allowUnregisteredUsers: true },
    );

    for (const [idProp, id] of getIdsFromSealdDisplayIds(
      entityUsersDisplayIds,
    )) {
      await deleteSealdAccess({
        [idProp]: id,
        seald_id: session?.sessionId as string,
      });
    }
  } catch (inviteError) {
    console.error(
      `Seald: Failed to revoke users ${JSON.stringify(
        entityUsersDisplayIds,
      )} of session ${sessionId}`,
      inviteError,
    );
  }
}
