import {
  SERVICE_BASIC_CARE,
  SERVICE_BASIC_MEDICAL_CARE,
  SERVICE_DEMENTIA,
  SERVICE_PALLIATIVE_CERTIFICATE,
  SERVICE_PSYCHIATRIC_CARE,
  SERVICE_SPECIALISED_INTENSIVE_NURSE,
  SERVICE_STATIC_CARE_DISABLED_PATIENTS,
} from "@recare/core/consts";
import { ONTOLOGY_SERVICE } from "@recare/core/model/utils/ontologies";
import {
  GetOntologyType,
  Patient,
  ProviderSearchPatient,
} from "@recare/core/types";
import Translations from "@recare/translations/types";

export const PROVIDER_SEARCH_OVERWEIGHT = 130;

export const PSEUDO_SERVICE_OVERWEIGHT = -99;

export const PROVIDER_SEARCH_SERVICES = [
  SERVICE_BASIC_CARE,
  SERVICE_BASIC_MEDICAL_CARE,
  SERVICE_DEMENTIA,
  SERVICE_PSYCHIATRIC_CARE,
  SERVICE_STATIC_CARE_DISABLED_PATIENTS,
  PSEUDO_SERVICE_OVERWEIGHT,
  SERVICE_SPECIALISED_INTENSIVE_NURSE,
  SERVICE_PALLIATIVE_CERTIFICATE,
];

export type ProviderSearchService = (typeof PROVIDER_SEARCH_SERVICES)[number];

export const getServiceItems = ({
  getOntology,
  services,
  translations,
}: {
  getOntology: GetOntologyType;
  services: number[];
  translations: Translations;
}): { id: number; label: string }[] => {
  return services.map((service) => {
    let label = getOntology({
      key: service,
      type: ONTOLOGY_SERVICE,
    });

    if (service === PSEUDO_SERVICE_OVERWEIGHT) {
      label = translations.providersearch.assessment.overweightService({
        weight: PROVIDER_SEARCH_OVERWEIGHT.toString(),
      });
    }

    return {
      id: service,
      label,
    };
  });
};

export const patientToProviderSearchServices = ({
  patient,
}: {
  patient?: Patient;
}): ProviderSearchService[] => {
  return [
    patient?.diagnosis?.mental_health?.dementia_description != null
      ? SERVICE_DEMENTIA
      : null,
    patient?.care_needs?.basic_medical_care != null
      ? SERVICE_BASIC_MEDICAL_CARE
      : null,
    patient?.care_needs?.base_care?.description != null
      ? SERVICE_BASIC_CARE
      : null,
    patient?.care_needs?.psychiatric_care != null
      ? SERVICE_PSYCHIATRIC_CARE
      : null,
    (patient?.profile?.weight_interval?.min ?? 0) >= PROVIDER_SEARCH_OVERWEIGHT
      ? PSEUDO_SERVICE_OVERWEIGHT
      : null,
    patient?.care_needs?.palliative?.palliative_certificate_needed
      ? SERVICE_PALLIATIVE_CERTIFICATE
      : null,
    patient?.care_needs?.breathing?.specialised_intensive_nurse
      ? SERVICE_SPECIALISED_INTENSIVE_NURSE
      : null,
    patient?.care_needs?.static_care_disabled_patients?.description != null
      ? SERVICE_STATIC_CARE_DISABLED_PATIENTS
      : null,
  ].filter(Boolean) as ProviderSearchService[];
};

const hasService = (services: number[], service: number): boolean => {
  return services.includes(service);
};

export const servicesToProviderSearchPatient = (
  services: ProviderSearchService[],
): Partial<ProviderSearchPatient> => {
  return {
    care_needs: {
      basic_medical_care: hasService(services, SERVICE_BASIC_MEDICAL_CARE)
        ? {
            description: "",
          }
        : undefined,
      base_care: hasService(services, SERVICE_BASIC_CARE)
        ? {
            description: "",
          }
        : undefined,
      psychiatric_care: hasService(services, SERVICE_PSYCHIATRIC_CARE)
        ? {
            description: "",
          }
        : undefined,
      static_care_disabled_patients: hasService(
        services,
        SERVICE_STATIC_CARE_DISABLED_PATIENTS,
      )
        ? {
            description: "",
          }
        : undefined,
      palliative: hasService(services, SERVICE_PALLIATIVE_CERTIFICATE)
        ? {
            palliative_certificate_needed: true,
            description: "",
          }
        : undefined,
      breathing: hasService(services, SERVICE_SPECIALISED_INTENSIVE_NURSE)
        ? {
            specialised_intensive_nurse: true,
          }
        : undefined,
    },
    diagnosis: {
      mental_health: hasService(services, SERVICE_DEMENTIA)
        ? {
            dementia_description: "",
          }
        : undefined,
    },
    profile: hasService(services, PSEUDO_SERVICE_OVERWEIGHT)
      ? {
          weight_interval: {
            max: null,
            min: PROVIDER_SEARCH_OVERWEIGHT,
          },
        }
      : undefined,
  };
};
