import { SxProps, Theme } from "@mui/material";
import { TextFieldProps } from "@mui/material/TextField";
import {
  DesktopTimePicker,
  DesktopTimePickerProps,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useDateLocale } from "@recare/core/hooks/useDateLocale";
import { InputWidthType, MUIPickersDate } from "@recare/core/types";
import { FormElementRenderProps, isValid } from "@recare/react-forms-state";
import { useTranslations } from "@recare/translations";
import { VERY_SMALL, inputWidth, margin } from "ds/materials/metrics";
import { FONT_SIZE_14, FONT_SIZE_16 } from "ds/materials/typography";
import { CSSProperties } from "react";
import { getLocaleText } from "../DatePickerInputField";
import { TextInputFieldProps } from "../TextInputField";
import { getHelperText } from "../Validation";

type PickedDesktopTimePickerProps<TDate> = Pick<
  DesktopTimePickerProps<TDate>,
  "minutesStep"
>;

type PickedTextFieldProps = Pick<TextFieldProps, "placeholder" | "required">;

type AdditionalTimePickerPresenterProps = {
  elementName: string;
  hasCustomValidation?: boolean;
  inputSx?: SxProps<Theme>;
  inputWidthType?: InputWidthType | undefined;
  label?: string;
  marginOverride?: string;
  onChange: (value: MUIPickersDate) => void;
  textInputVariant?: TextInputFieldProps["variant"];
  timeValue?: MUIPickersDate;
  validation: any;
  value: MUIPickersDate;
  width?: string;
  wrapperStyle?: CSSProperties;
};

type AdditionalTimePickerProps<TDate> = {
  sideMutation?: (v: TDate, mutateElement: AnyFunction) => void;
} & Omit<
  AdditionalTimePickerPresenterProps,
  "onChange" | "validation" | "value"
>;

export type TimePresenterProps<TDate = MUIPickersDate> =
  AdditionalTimePickerPresenterProps &
    PickedDesktopTimePickerProps<TDate> &
    PickedTextFieldProps;

export function TimePickerPresenter({
  elementName,
  minutesStep = 5,
  label,
  placeholder,
  required,
  width,
  inputSx,
  inputWidthType = VERY_SMALL,
  marginOverride,
  hasCustomValidation = false,
  textInputVariant = "standard",
  wrapperStyle,
  timeValue,
  onChange,
  value,
  validation,
}: TimePresenterProps<MUIPickersDate>) {
  const translations = useTranslations();
  const { currentLocale } = useDateLocale();

  return (
    <div
      style={{
        width: width || inputWidth(inputWidthType),
        margin: marginOverride || margin(0),
        ...wrapperStyle,
      }}
    >
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={currentLocale}
        localeText={getLocaleText(currentLocale.code)}
      >
        <DesktopTimePicker
          ampm={false}
          minutesStep={minutesStep}
          onChange={(newValue) => {
            if (!newValue) {
              return onChange(null);
            }

            return onChange(newValue);
          }}
          slotProps={{
            inputAdornment: {
              position: "end",
              style: { margin: margin(0, 1, 0, 0) },
            },
            textField: {
              error: !isValid(validation),
              helperText: getHelperText({
                hasCustomValidation: !!hasCustomValidation,
                translations,
                validation,
              }),
              id: `input_text_${elementName}`,
              InputLabelProps: { style: { fontSize: FONT_SIZE_14 } },
              inputProps: {
                placeholder: placeholder,
                readOnly: false,
                style: { fontSize: FONT_SIZE_14 },
                "data-testid": `input_text_${elementName}`,
              },
              label,
              placeholder,
              required,
              sx: {
                svg: {
                  height: FONT_SIZE_16,
                  width: FONT_SIZE_16,
                },
                ...inputSx,
              },
              variant: textInputVariant,
            },
          }}
          value={timeValue ?? value}
        />
      </LocalizationProvider>
    </div>
  );
}

export type TimePickerProps<TDate = MUIPickersDate> =
  AdditionalTimePickerProps<TDate> &
    PickedDesktopTimePickerProps<TDate> &
    PickedTextFieldProps;

export default function TimePicker({
  elementName,
  minutesStep = 5,
  label,
  placeholder,
  required,
  width,
  inputSx,
  inputWidthType = VERY_SMALL,
  marginOverride,
  hasCustomValidation = false,
  textInputVariant = "standard",
  sideMutation,
  wrapperStyle,
  timeValue,
}: TimePickerProps<MUIPickersDate>) {
  return (
    <FormElementRenderProps
      elementName={elementName}
      sideMutation={sideMutation}
    >
      {({
        onChange,
        validation,
        value,
      }: {
        onChange: (value: MUIPickersDate) => void;
        validation: any;
        value: MUIPickersDate;
      }) => (
        <TimePickerPresenter
          elementName={elementName}
          minutesStep={minutesStep}
          label={label}
          placeholder={placeholder}
          required={required}
          width={width}
          inputSx={inputSx}
          inputWidthType={inputWidthType}
          marginOverride={marginOverride}
          hasCustomValidation={hasCustomValidation}
          textInputVariant={textInputVariant}
          wrapperStyle={wrapperStyle}
          timeValue={timeValue}
          onChange={onChange}
          validation={validation}
          value={value}
        />
      )}
    </FormElementRenderProps>
  );
}
